import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import logo from '../images/Easy Travel Experrs Logo.svg'
import shape from '../images/Shape-Modal-white.svg'
import { Link } from "gatsby";

function IndexPage({ data }) {
  const { contentfulModal } = data;
  const { titel, beschreibung: { beschreibung }, schritt1Text, schritt2Text, bild: { file: { url }} } = contentfulModal;
  const [business, setBusiness] = useState(false);
  const [privat, setPrivate] = useState(false);

  const currentModal = () => {
    if (!business && !privat) {
      return (
        <IntroModal
          titel={titel}
          url={url}
          beschreibung={beschreibung}
          setBusiness={setBusiness}
          setPrivate={setPrivate}
          schritt1Text={schritt1Text}
        />
      );
    }
    return business ? (
      <BusinessModal
        titel={titel}
        url={url}
        beschreibung={beschreibung}
        schritt2Text={schritt2Text}
      />
    ) : (
      <PrivateModal
        titel={titel}
        url={url}
        beschreibung={beschreibung}
        schritt2Text={schritt2Text}
      />
    );
  }

  return (
    <Layout headerless className="bg-blue-500">
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="bg-blue-500 px-6 py-6 h-full">
        <div className="max-w-xl mx-auto max-h-xl md:mt-16">
          { currentModal() };
        </div>
      </section>
    </Layout>
  );
}

const IntroModal = ({titel, url, beschreibung, schritt1Text, setBusiness, setPrivate}) => {
  return (
    <div className="card bg-white rounded-sm h-full">

      <div className="mb-8 bg-blue-200 p-8 md:p-16 relative">
        <img src={shape} className="absolute bottom-0 inset-x-0" alt=""/>
        <figure>
          <img className="h-24 mx-auto mb-2" src={logo} alt="Logo"/>
        </figure>
      </div>

      <div className="p-8 pt-0 md:p-16 md:pt-0 ">
        <h3 className="text-blue-500 font-bold mb-4 text-lg leading-snug vice">
          {titel}
        </h3>
        <p className="text-gray-600 font-light mb-8">
          {beschreibung}
        </p>

        <div className="flex flex-row mb-8">
          <img className="h-16 rounded-full" src={url} alt=""/>
          <div className="ml-4 flex flex-col justify-center">
            <div className="font-bold text-lg">Diana Naumann</div>
            <div className="text-gray-600 mb-8">Global Travel Manager</div>
          </div>
        </div>

        <h4 className="mb-4 text-blue-500 font-bold text-lg vice">
          {schritt1Text}
        </h4>
        <button className="btn-modal" onClick={() => setBusiness(true)}>
        Geschäftskunde
        </button>
        <button className="btn-modal" onClick={() => setPrivate(true)}>
        Privatperson, Schule, Verein
        </button>
      </div>

    </div>
  )
}

const BusinessModal = ({titel, url, beschreibung, schritt2Text}) => {
  return (
    <div className="card bg-white rounded-sm h-full">

      <div className="mb-8 bg-blue-200 p-8 md:p-16 relative">
        <img src={shape} className="absolute bottom-0 inset-x-0" alt=""/>
        <figure>
          <img className="h-24 mx-auto mb-2" src={logo} alt="Logo"/>
        </figure>
      </div>
      <div className="p-8 pt-0 md:p-16 md:pt-0">
        <h3 className="text-blue-500 font-bold mb-4 text-lg leading-snug vice">
          {titel}
        </h3>
        <p className="text-gray-600 font-light mb-8">
          {beschreibung}
        </p>
        
        <div className="flex flex-row mb-8">
          <img className="h-16 rounded-full" src={url} alt=""/>
          <div className="ml-4 flex flex-col justify-center">
            <div className="font-bold text-lg">Diana Naumann</div>
            <div className="text-gray-600 mb-8">Global Travel Manager</div>
          </div>
        </div>

        <h4 className="mb-4 text-blue-500 font-bold text-lg vice">
          {schritt2Text}
        </h4>
        <Link className="btn-modal" to="/business">
        Geschäftsreise
        </Link>
        <Link className="btn-modal" to="/incentive">
        Incentivereise
        </Link>
      </div>
    </div>
  )
}

const PrivateModal = ({titel, url, beschreibung, schritt2Text}) => {
  return (
    <div className="card bg-white rounded-sm h-full">

    <div className="mb-8 bg-blue-200 p-8 md:p-16 relative">
      <img src={shape} className="absolute bottom-0 inset-x-0" alt=""/>
      <figure>
        <img className="h-24 mx-auto mb-2" src={logo} alt="Logo"/>
      </figure>
    </div>
      <div className="p-8 pt-0 md:p-16 md:pt-0">
        <h3 className="text-blue-500 font-bold mb-4 text-lg leading-snug vice">
          {titel}
        </h3>
        <p className="text-gray-600 font-light mb-8">
          {beschreibung}
        </p>
        
        <div className="flex flex-row mb-8">
          <img className="h-16 rounded-full" src={url} alt=""/>
          <div className="ml-4 flex flex-col justify-center">
            <div className="font-bold text-lg">Diana Naumann</div>
            <div className="text-gray-600 mb-8">Global Travel Manager</div>
          </div>
        </div>
        <h4 className="mb-4 text-blue-500 font-bold text-lg">
          {schritt2Text}
        </h4>
        <Link className="btn-modal" to="/schooltrip">
          Klassenfahrt
        </Link>
        <Link className="btn-modal" to="/private">
          Privatreise
        </Link>
        <Link className="btn-modal" to="/group">
          Gruppenreise
        </Link>
      </div>
    </div>
  )
}

export default IndexPage;

export const pageQuery = graphql`query ModalQuery {
  contentfulModal {
    beschreibung {
      beschreibung
    }
    bild {
      file {
        url
      }
    }
    schritt1Text
    schritt2Text
    titel
  }
}`


